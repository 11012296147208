import { WebStorageStateStore } from 'oidc-client-ts';
import ApplicationService from '@Services/motor/application';
import Cookies from 'js-cookie';

const phParam = localStorage.getItem('ph')?.length === 10 ? localStorage.getItem('ph') : undefined;
// const onSigninCallback = (_user: User | void): void => {
const onSigninCallback = (): void => {
  ApplicationService.updateCreatedBy().then(() => {
    const ele = window.parent.document.getElementById('login-popup-close-btn');
    ele && ele.click();
    // if (_user?.access_token) {
    //   if (
    //     (window.parent.location.pathname === '/' || window.parent.location.pathname === '/en') &&
    //     phParam
    //   ) {
    //     // window.parent.location.href = window.parent.location.origin + '/Individual/ProfilePage';
    //     window.parent.location.href =
    //       window.parent.location.origin +
    //       `/UHome/PurchaseFlow/?appflow=renewal&ph=${_user.profile.IdentityNumber}`;
    //   } else {
    window.parent.location.reload();
    // }
    // }
  });
};
export const oidcConfig = {
  authority: window?.settings?.VITE_AUTH_AUTHORITY,
  client_id: window?.settings?.VITE_AUTH_CLIENT_ID,
  redirect_uri: window?.settings?.VITE_AUTH_REDIRECT_URI,
  // scope: 'address email openid profile roles',
  scope: 'address email openid profile roles offline_access api-profile',
  client_secret: 'rasan@123',
  ui_locales: Cookies.get('i18next'),
  onSigninCallback,
  userStore: new WebStorageStateStore({
    store: localStorage,
  }),
  extraQueryParams: {
    source_origin: window?.parent?.location?.href,
    identity_number: phParam,
  },
};
