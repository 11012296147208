export const ErrorKeys = new Map([
  ['birthMonth', 'MO031,22,KYC005,24,25,39,43,47,52'], //تاريخ الميلاد
  ['phid0', 'MO027,56,KYC015,37,SC004,SC010,MO019,SC015,30,42,81,16,51,55,KYC006,KYC010'], //رقم الهوية
  ['phid', 'MO027,56,37,SC004,SC010,MO019,SC015,30,42,81,16,51,55,KYC006,KYC010,KYC037'],
  ['phid1', 'MO027,56,KYC015,37,SC004,SC010,MO019,SC015,30,42,81,16,51,55,KYC006,KYC010'],
  ['phid2', 'MO027,56,KYC015,37,SC004,SC010,MO019,SC015,30,42,81,16,51,55,KYC006,KYC010,MB003'],
  ['documentNoMojaz', '1,8,9,14,18,20,26,28,34,46,37,29,2,KYC014,KYC003'], //رقم التسلسلى
  ['documentNo', '1,8,9,14,18,20,26,28,34,46,37,29,2,KYC014,KYC003'], //رقم التسلسلى
  ['documentNo0', '1,8,9,14,18,20,26,28,34,46,37,29,2,KYC014,KYC003'], //رقم التسلسلى
  ['documentNo1', '1,8,9,14,18,20,26,28,34,46,37,29,2,KYC014,KYC003'],
  ['documentNo3', '1,8,9,14,18,20,26,28,34,46,37,29,2,KYC014,KYC003'],
  ['documentNo2', '5,10,11,36,38,65,75,KYC004'], //البطاقة الجمركية
  ['yearOfManufacture', 'MSD006,23,31,33,-3,KYC001'],
  ['tplPlainCaptcha', 'MO003,SC014'], //captcha
  ['licenseType', ''],
  ['licenseYears', ''],
  ['city', ''],
  ['district', ''],
  ['streetName', ''],
  ['make', ''],
  ['model', ''],
  ['capacity', ''],
  ['manufactureYear', 'MSD006,23,31,33,-3,KYC001'],
  ['yearOfManufactureSeq', 'MSD006,23,31,33,-3,KYC001'],
  ['yearOfManufactureCustome', 'MSD006,23,31,33,-3,KYC001'],
  ['purposeOfUse', 'MO033,MO05'],
  ['effectiveDate', 'MO07'],
  ['insuranceType', 'MO017'],
  ['estimatedValue', 'MO020'],
  ['repairMethod', 'MO018'],
  ['driverId', 'D007,D012,71,69,KYC012,MO027,KYC002'], //driver
  ['driverBirth', 'MO031,22,KYC005,24,25,39,43,47,52,D004'], //driver
  ['birthMonth', 'MO031,22,KYC005,24,25,39,43,47,52,D004'], //driver
  ['maritalStatus', ''], //driver
  ['email', 'SC005,SC008,SC006,SC003,SC001,SC016,FailedToValidateScheme,SchemeAlreadyVerified'], //scheme
  ['entity', 'SC012,SC004,SC003,SC001,FailedToValidateScheme,SchemeAlreadyVerified'], //scheme
  ['promoCode', 'SC011,SC003,SC001,FailedToValidateScheme,SchemeAlreadyVerified'], //scheme
  ['emailCode', 'SC006,SC003,SC001,SC013,SC009,FailedToValidateScheme,SchemeAlreadyVerified'], //scheme
  ['txtShareMobile', 'D001'],
  ['txtShareEmail', 'D001'],
  ['phoneInput', 'InvalidMobileNumber,D001'],
  ['mobileNumber', 'NotUnique'],
  ['modification', 'MO028'],
  ['workNameAndLocation', 'MO029'],
  ['trailEstimatedValue', 'MO030'],
  ['quoteEstimatedValue', ''], // estimated value step 3
  ['odometerReading', 'MB006'],
  ['mobile', 'OTP002,OTP004,NotallowedOperration'],
  ['newPhoneNumber', 'OTP002,OTP004'],
  ['otp', 'OTP001,OTP002,OTP003,OTP005,AUTH001,AUTH005,AUTH006,AUTH009,AUTH019,AUTH020'],
  [
    'couponError',
    'CouponRequired,CouponNotFound,CouponNotValidForPolicyHolder,CouponExpired,QuoteResponseNotFound,CouponNotActive,CouponInvalidProduct,CouponNotInRange,PolicyPremiumNotValidForCoupon,CouponLimitExceededByPolicyHolder,RULE-001,NotallowedOperration,CouponAlreadyApplied',
  ],
  [
    'QitafModelError',
    'QitafUnexpectedError,PY-1017,PY-1019,PY-1022,PY-1070,PY-1109,PY-1,PY-2310,PY-2311,PY-1007,PY-1015,PY-1030,PY-1014,PY-1050,PY-1012,PY-1040,PY-2007,PY-1018,PY-1021,PY-914,PY-1046,PY-1829,PY-1020,PY-2510,PY-1045,PY-1041,PY-1044',
  ],
  ['QitafReverseError', 'NotallowedOperration'],
  [
    'motorTabGerenal',
    'MSD011,MSD012,MSD013,21,KYC013,D001,6,7,49,15,13,17,19,44,KYC007,KYC008,MO026,2001,E2001,100,KYC011,3,SC009,MO002,MO009,MO013,MO014,MO015,MO021,MO024,1000,E1000,KYC016,E1001,E1002,MO022,71,69,KYC012,MO025,,MO032,KYC002,T0030,KYC021,AUTH-001,AUTH-002,AUTH-003,AUTH-004,AUTH-005,AUTH-006,AUTH-007,AUTH-008,AUTH-009,AUTH-010,AUTH-011,AUTH-012,AUTH-013,AUTH-014,PERM-001,PERM-002,PERM-003,PERM-004,PERM-005,PERM-006,RQST-014,RQST-015,RQST-016,SERV-001,RQST-116,RQST-011,RQST-012,RQST-101,RQST-114,SERV-002,SERV-003,SERV-004,KYC0155,KYC0152,KYC0151,KYC0153,Watheq01,Watheq02,KY016,RULE-001',
  ], //for pageGeneralError
  [
    'driverGerenal',
    '21,KYC013,D001,D002,D003,D006,D008,D010,MO026,2001,E2001,100,KYC011,3,SC009,MO002,MO009,MO013,MO014,MO015,MO021,MO024,1000,E1000,KYC016,E1001,E1002,RULE-001',
  ], //for pageGeneralError
  [
    'addDriverGerenal',
    '21,KYC013,D001,D005,D009,D010,MO016,MO026,2001,E2001,100,KYC011,3,SC009,MO002,MO009,MO013,MO014,MO015,MO021,MO024,1000,E1000,KYC016,E1001,E1002,D013,D011,KYC0155,RULE-001',
  ], //for pageGeneralError
  [
    'otherDetailsGerenal',
    '21,KYC013,D001,MO026,2001,E2001,100,KYC011,3,SC009,MO002,MO009,MO013,MO014,MO015,MO021,MO024,1000,E1000,KYC016,E1001,E1002,RULE-001',
  ], //for pageGeneralError
  [
    'insuranceGeneral',
    '21,KYC013,D001,MO026,2001,E2001,100,KYC011,3,SC009,MO002,MO009,MO013,MO014,MO015,MO021,MO024,1000,E1000,KYC016,E1001,E1002,SC016,D005,D009,RULE-001',
  ], //for pageGeneralError
  ['livePhotoErrorGeneral', 'SMSLimitExceed,RULE-001'],
  [
    'MBIStepOneGeneral',
    'MB005,MB007,MO025,Quotes001,KYC015,KYC052,KYC02001,KYC000,MB001,MB002,MB003,MB004,KYC046,MSD011,1000,E1000,KYC016,E1001,E1002,MO022,71,69,KYC012,MO025,,MO032,KYC002,T0030,KYC021,AUTH-001,AUTH-002,AUTH-003,AUTH-004,AUTH-005,AUTH-006,AUTH-007,AUTH-008,AUTH-009,AUTH-010,AUTH-011,AUTH-012,AUTH-013,AUTH-014,PERM-001,PERM-002,PERM-003,PERM-004,PERM-005,PERM-006,RQST-014,RQST-015,RQST-016,SERV-001,RQST-116,RQST-011,RQST-012,RQST-101,RQST-114,SERV-002,SERV-003,SERV-004,KYC0155,KYC0152,KYC0151,KYC0153,Watheq01,Watheq02,KY016,RULE-001',
  ],
  ['MBIQuoteListGeneral', 'MB005,MO06,MB001,MB004,Quotes002,MB007,RULE-001'],
  ['MBIPaymentGeneral', 'MB005,MB023,MB001,MB004,MSD011,Quotes002,RULE-001'],
  ['MBISuccessGeneral', 'MB005,MB001,MB004,PO01,PO02,DO01,MO001,RULE-001'],
  ['RenewalGeneral', 'TYN002,LnE001,PRN001,TYN001,RULE-001,PolicyAlreadyRenewed'],
  ['MBIMissingData', 'Quotes001,MB007,RULE-001'],
  [
    'MojazGeneral',
    'MOJ005,MOJ001,MOJ002,MOJ003,MOJ004,PO01,PO01,KYC033,TU01,MOJ007,1000,E1000,KYC016,E1001,E1002,MO022,71,69,KYC012,MO025,,MO032,KYC002,T0030,KYC021,AUTH-001,AUTH-002,AUTH-003,AUTH-004,AUTH-005,AUTH-006,AUTH-007,AUTH-008,AUTH-009,AUTH-010,AUTH-011,AUTH-012,AUTH-013,AUTH-014,PERM-001,PERM-002,PERM-003,PERM-004,PERM-005,PERM-006,RQST-014,RQST-015,RQST-016,SERV-001,RQST-116,RQST-011,RQST-012,RQST-101,RQST-114,SERV-002,SERV-003,SERV-004,KYC0155,KYC0152,KYC0151,KYC0153,Watheq01,Watheq02,KY016,RULE-001',
  ],
  [
    'ProfileGeneral',
    'Profile01,Profile02,Profile03,Profile04,Profile05,Profile07,Tahaqaq01,Tahaqaq02,KYC022,OTP002,RULE-001,AUTH028',
  ],

  ['MotorPaymentGeneral', 'MB005,MB023,MB001,MB004,MSD011,Quotes002,RULE-001'],
  [
    'SummaryPageGeneral',
    'Tahaqaq01,Tahaqaq02,KYC022,OTP002,RULE-001,MO037,CompQuoteMustHaveFeature,InvalidEmail,NotUnique,NotallowedOperration,T0030,ApplicationAlreadyPaid,TamaraIsInvalidForThisQuote,PY062,PY055,InvalidAmount,LivePhotoNotFound,MO015,QEX001,PY021,PY005,QuoteMustHavePreSelectedFeature,CouponOrQitafNotAllowed',
  ],
  // ["MojazReportGeneral","MB005,MB001,MB004,PO01,PO02,DO01,MO001,"]
  [
    'RetryPaymentGeneral',
    'MO001,QEX001,CreatingInvoiceNotAllowed,PY062,RULE-001,PY021,PY005,T0030,PO02,RULE-001,T0030',
  ],
  ['RetryPaymentFailed', 'PO02,RULE-001,T0030'],
  ['SuggestRenewal', 'Suggest002,RULE-001'],
  [
    'successPaymentGeneral',
    'downloadError,downloadPolicyPaperError,downloadInsuranceCardError,downloadReceiptError,downloadTermsAndConditionstError,PolicyNotAcknowldged',
  ],
  ['qitafMobileNumber', 'Ineligible to earn,QitafUnexpectedError,NotallowedOperration'],
  ['policyHolderEmail', 'DO01'],
  [
    'OdRenewalFailed',
    'MO009,MalathAddonIsExpired,MalathAddonIsPaid,NoDueToInstallment,MalathAddonIsCanceled,InstallmentNotFound,PO02',
  ],
  ['changePhoneNumber', 'AUTH001,AUTH009,AUTH016,AUTH018,AUTH019,AUTH024'],
  ['changeEmail', 'AUTH001,AUTH009,AUTH019,AUTH025'],
  ['changePassword', 'AUTH001,AUTH004,AUTH009,AUTH010,AUTH017,AUTH019,AUTH027'],
]);
