import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PaymentReceiptPopup from './PaymentReceiptPopup';
import MBIServices from '../../../services/mbi/application';
import { dataLayerPush, getEnglishFromKey } from '../../../services/ga4';
import { ErrorsTypes, FLOWS, STEPS } from '@models/enums/ga4.enum';
import { GA4Events } from '@Services/ga4Events';
import Img from '@Components/common/Img';
import useModal from '@Hooks/useModal';
import { TMBIApplicationSummary } from '@models/mbi.model';
import { PageErrorMessage } from '@Components/common/PageErrorMessage';

type Props = {
  appRef: string | undefined;
  applicationSummary: TMBIApplicationSummary;
};
export default function SuccessIntro({ appRef, applicationSummary }: Props) {
  const {
    t,
    i18n: { language },
  } = useTranslation(['mbi']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [policyURL, setPolicyURL] = useState<string | undefined>(undefined);
  const { showModal, modalTarget, handleShowModal } = useModal();
  const [errorKey, setErrorKey] = useState<string[]>([]);

  // Get Application Summary
  const DownloadPolicyPaper = async () => {
    dataLayerPush(GA4Events.printPolicyPaper.event, [
      {
        key: GA4Events.printPolicyPaper.params.flowName,
        value: FLOWS.MBI,
      },
      {
        key: GA4Events.printPolicyPaper.params.stepName,
        value: STEPS.SUCCESS_PAGE,
      },
    ]);

    dataLayerPush(GA4Events.downloadFiles.event, [
      {
        key: GA4Events.downloadFiles.params.flowName,
        value: FLOWS.MBI,
      },
      {
        key: GA4Events.downloadFiles.params.stepName,
        value: STEPS.SUCCESS_PAGE,
      },
      {
        key: GA4Events.downloadFiles.params.fileTitle,
        value: `MBI-Report-${applicationSummary?.vehicleSummary?.sequenceNumber}.pdf`,
      },
    ]);
    if (appRef) {
      setIsLoading(true);
      if (policyURL) {
        window.open(policyURL);
        setIsLoading(false);
      } else {
        try {
          const data = await MBIServices.getDownloadPolicyDocument(appRef);
          if (data) {
            setPolicyURL(data?.url);
            window.open(data?.url);
            setIsLoading(false);
          }
        } catch (err: any) {
          setIsLoading(false);
          setErrorKey(err?.response?.data?.errors);
          dataLayerPush(GA4Events.errorCaptured.event, [
            {
              key: GA4Events.errorCaptured.params.flowName,
              value: FLOWS.MBI,
            },
            {
              key: GA4Events.errorCaptured.params.stepName,
              value: STEPS.SUCCESS_PAGE,
            },
            {
              key: GA4Events.errorCaptured.params.errorCode,
              value: '400',
            },
            {
              key: GA4Events.errorCaptured.params.errorMessage,
              value:
                language === 'en'
                  ? t(`common:${err?.response?.data?.errors?.[0]}`)
                  : await getEnglishFromKey(err?.response?.data?.errors?.[0]),
            },
            {
              key: GA4Events.errorCaptured.params.type,
              value: ErrorsTypes.REQUEST,
            },
          ]);
        }
      }
    }
  };

  return (
    <>
      <section className='relative py-5 px-4' data-testid='policy-actions'>
        <div className='relative z-10 flex flex-col items-center justify-center'>
          <Img src='/Resources/images/tameeni-icon-blue.svg' width={50} alt='' />
          <h4 className='text-size-22 mt-4'>{t('mbi:congratulation_you_got_your_policy')}</h4>

          <p className='text-sm whitespace-break-spaces mb-0'>
            <span>{t('mbi:you_can')}</span>{' '}
            <button
              className='text-secondary-100 font-bold px-0'
              onClick={() => {
                DownloadPolicyPaper();
                setIsLoading(false);
              }}
            >
              {t('mbi:download')}
            </button>{' '}
            <span>{t('mbi:and')}</span>{' '}
            <button
              className='text-secondary-100 font-bold p-0'
              onClick={() => {
                DownloadPolicyPaper();
                setIsLoading(false);
              }}
            >
              {t('mbi:print')}
            </button>{' '}
            <span>{t('mbi:policy_paper_with_following_options')}</span>
          </p>
          <span className='text-sm'>{t('mbi:copy_of_insurance_policy_sent_to_email')}</span>

          <button
            className='ta btn bg-secondary border-0 px-4 h-[58px] py-5 text-base rounded-[15px] mt-4 hover:text-white hover:bg-secondary '
            onClick={DownloadPolicyPaper}
          >
            {isLoading ? (
              <Img src={'/Resources/images/loading.gif'} width={34} alt='Loader' title='Loader' />
            ) : (
              <>
                <span className='flex items-center justify-center bg-white rounded-full w-[42px] h-[42px] me-[5px]'>
                  <Img
                    src='/Resources/images/policypaper-icon.svg?v=25.06'
                    alt=''
                    width={34}
                    height={34}
                    className='me-[5px]'
                  />
                </span>
                <span className='text-white text-inherit'> {t('mbi:policy_paper')}</span>
              </>
            )}
          </button>

          <div className='flex flex-col sm:flex-row gap-3'>
            <a
              className='ta btn btn-white px-4 h-[58px] py-5 text-base rounded-[15px] mt-4 hover:text-white hover:bg-secondary'
              id={'payment-receipt-popup-trigger'}
              href={'#payment-receipt-popup'}
              data-target-modal='#payment-receipt-popup'
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                dataLayerPush(GA4Events.printPolicyReceipt.event, [
                  {
                    key: GA4Events.printPolicyReceipt.params.flowName,
                    value: FLOWS.MBI,
                  },
                  {
                    key: GA4Events.printPolicyReceipt.params.stepName,
                    value: STEPS.SUCCESS_PAGE,
                  },
                ]);

                handleShowModal(e);
              }}
            >
              <Img
                src='/Resources/images/share-pdfemail-icon.svg'
                alt=''
                width={34}
                height={34}
                className='me-[5px]'
              />
              <span className='text-inherit'>{t('mbi:payment_receipt')}</span>
            </a>
          </div>
        </div>
        <div>
          {/* Left */}
          <Img
            className='absolute bottom-0 left-0 z-0'
            src='/Resources/images/success-page-left.svg'
            alt=''
          />
          {/* Right */}
          <Img
            className='absolute bottom-0 right-0 z-0'
            src='/Resources/images/success-page-right.svg'
            alt=''
          />
        </div>

        {/* Payment Receipt popup */}
        {showModal && modalTarget === '#payment-receipt-popup' && (
          <PaymentReceiptPopup
            applicationSummary={applicationSummary}
            DownloadPolicyPaper={DownloadPolicyPaper}
            isLoading={isLoading}
          />
        )}
      </section>
      {errorKey?.length > 0 && <PageErrorMessage error={errorKey} inputKey='MBISuccessGeneral' />}
    </>
  );
}
