import { cn } from '@tameeni/shared-logic';

interface IImgProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string;
  isLazyLoading?: boolean;
  isCaptcha?: boolean;
}

export default function Img({
  src,
  title,
  className,
  isLazyLoading = true,
  isCaptcha = false,
  ...rest
}: IImgProps) {
  return (
    <img
      src={isCaptcha ? src : window.settings.VITE_RESOURCES_BASE_URL + src}
      alt={title}
      title={title}
      className={cn('max-w-full', className)}
      loading={isLazyLoading ? 'lazy' : 'eager'}
      {...rest}
    />
  );
}
